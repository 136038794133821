// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynpages-estate-page-tsx": () => import("./../../../src/dynpages/EstatePage.tsx" /* webpackChunkName: "component---src-dynpages-estate-page-tsx" */),
  "component---src-dynpages-post-page-tsx": () => import("./../../../src/dynpages/PostPage.tsx" /* webpackChunkName: "component---src-dynpages-post-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktivitet-tsx": () => import("./../../../src/pages/aktivitet.tsx" /* webpackChunkName: "component---src-pages-aktivitet-tsx" */),
  "component---src-pages-fastigheter-tsx": () => import("./../../../src/pages/fastigheter.tsx" /* webpackChunkName: "component---src-pages-fastigheter-tsx" */),
  "component---src-pages-for-hyresgaster-tsx": () => import("./../../../src/pages/for-hyresgaster.tsx" /* webpackChunkName: "component---src-pages-for-hyresgaster-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */)
}

